<template>
  <v-container class="mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
      {{ "Gerenciar Encomendas" }}
    </v-subheader>
    <v-card flat outlined>
      <v-card-title>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          flat
          solo
          :disabled="items.length == 0 ? true : false"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          dark
          class="mb-2"
          to="/encomendas-padrao-cte/new"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          depressed
          dark
          class="mb-2 ml-2"
          @click="initialize"
        >
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-0"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on }">
              <v-icon
                @click="[printItem(item.id)]"
                :disabled="item.status == 'CANCELADA' ? true : false"
                v-on="on"
                color="success"
                >mdi-printer</v-icon
              >
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-tooltip bottom color="red">
            <template v-slot:activator="{ on }">
              <v-icon
                :disabled="item.status == 'CANCELADA' ? true : false"
                @click="cancelItem(item.id)"
                v-on="on"
                color="error"
                >mdi-cancel</v-icon
              >
            </template>
            <span>Cancelar Encomenda</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <orderPrint style="display: none" :dados="dadosRecuperados" />
  </v-container>
</template>

<script>
import orderPrint from "@/components/printers/bilhets/orders.vue";
export default {
  components: {
    orderPrint,
  },
  data: () => ({
    dialog: false,
    search: "",
    dadosRecuperados: [],
    headers: [
      { text: "Cód.Barras", value: "cod_barras", sortable: false },
      { text: "Ag.Origem", value: "origem", sortable: false },
      { text: "Ag.Destino", value: "destino", sortable: false },
      { text: "Remetente", value: "remetente", sortable: false },
      { text: "Tel.Rem", value: "fone_remetente", sortable: false },
      { text: "Destinatario", value: "destinatario", sortable: false },
      { text: "Tel.Dest", value: "fone_destinatario", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
  }),
  created() {
    // this.initialize();
  },

  methods: {
    printItem(id) {
      this.$http
        .get(`/encomendas/${id}`)
        .then((r) => {
          this.dadosRecuperados = r.data[0];
          setTimeout(() => {
            this.$htmlToPaper("imprimir");
          }, 200);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/encomendas/por-agencia/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.items = r.data;
          this.$store.dispatch("activeLoader", false);
        });
    },
    editar(id) {
      this.$router.push(`/encomendas/editar/${id}`);
    },
    cancelItem(id) {
      let c = confirm("Deseja cancelar a encomenda?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .post(`/encomendas/cancelar/${id}`, {
            id_encomenda: id,
            id_operador: this.$store.getters.getOperador,
            id_agencia: this.$store.getters.getOpAgency,
            status: "CANCELADA",
          })
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            alert(r.data.result);
            this.initialize();
          });
      }
    },
  },
};
</script>

